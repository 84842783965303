import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCheckList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title', sortable: true },
    { key: 'no_of_checklist_items', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalChecklist = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalChecklist.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchChecklist = (ctx, callback) => {
    store
      .dispatch('app-employees/fetchChecklists', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const checklists = response.data.data
        const { total } = response.data.meta

        callback(checklists)
        totalChecklist.value = total
      })
      .catch(() => {
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching checklists',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })

        toast({
          component: ToastificationContent,
          props: {
            title: `Error fetching checklists`,
            icon:'AlertTriangleIcon',
            variant : 'danger',
            hideClose: true,
            
          },},{
          timeout : 3000,
          position : 'bottom-center',
          toastClassName:"error-info",
          hideProgressBar : true,
              })
      })
  }

  return {
    fetchChecklist,
    tableColumns,
    perPage,
    currentPage,
    totalChecklist,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
  }
}
